<template>
  <div class="order-list-wrap">
    <div class="button-wrap">
      <el-button type="primary" style="width: 100%" :disabled="getOrderList.length === 0" @click="downloadList">
        下載CSV
      </el-button>
    </div>
    <el-table
      ref="listTable"
      :data="getOrderList"
      height="100%"
      style="width: 100%"
      header-cell-class-name="cus-table-header"
      row-class-name="cus-table-row"
      empty-text="尚無資料"
    >
      <el-table-column prop="shop_name" label="門市" width="100">
        <template #default="scope">
          <span>
            {{ scope.row.shop_erp_id }}
            <br />
            {{ scope.row.shop_name }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="create_datetime" label="交易日期-時間" width="150">
        <template #default="scope">
          <span>
            {{ formatDateTime('YYYY/MM/DD HH:mm', scope.row.create_datetime) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="source_platform" label="來源-類型" width="130" />
      <el-table-column prop="status_code" label="訂單狀態" width="100">
        <template #default="scope">
          <el-tag v-if="mapOrderStatus[scope.row.status_code]" :type="mapOrderStatus[scope.row.status_code].type">
            {{ mapOrderStatus[scope.row.status_code].label }}
          </el-tag>
          <el-tag v-else type="info">待確認</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="serial_number" label="取餐號碼" min-width="130" />
      <el-table-column prop="invoice_no" label="電子發票號碼" width="130" />
      <el-table-column prop="invoice_amount" label="發票金額" width="120" />
      <el-table-column prop="invoice_mode_description" label="發票模式" width="100" />
      <el-table-column prop="invoice_status" label="電子發票狀態" width="120">
        <template #default="scope">
          <span>{{ mapInvoiceStatus[scope.row.invoice_status]?.label }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="invoice_last_action_time" label="電子發票異動時間" width="150">
        <template #default="scope">
          <span>
            {{
              scope.row.invoice_last_action_time.length >= 12
                ? formatDateTime('YYYY/MM/DD HH:mm', scope.row.invoice_last_action_time)
                : scope.row.invoice_last_action_time
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="" label="消費者類型" width="100">
        <template #default="scope">
          <span>{{ getConsumer(scope.row.user_id, scope.row.want_card_number) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="level_description" label="旺卡等級" min-width="100" />
      <el-table-column fixed="right" align="right" width="150">
        <template #default="scope">
          <el-button
            type="info"
            plain
            style="font-weight: normal; border: none; width: 110px"
            @click="handleClickDetail(scope.$index, scope.row)"
          >
            詳細
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      v-model:currentPage="currentPage"
      v-model:page-size="getPageSize"
      layout="prev, pager, next, jumper"
      :total="getPageTotal"
      @click="handleCurrentChange"
    />
  </div>
</template>
<script>
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useGetters, useMutations, useActions } from '@/utils/mapStore';
import { downloadCSV } from '@/utils/download';
import { getFormatDate, formatDateTime } from '@/utils/datetime';
import { mapInvoiceStatus, mapOrderStatus, getConsumer } from '@/utils/define';
export default {
  name: 'order-list',
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { getOrderList, getPageSize, getPageTotal, getIsDetail, getSearchCondition } = useGetters('order', [
      'getOrderList',
      'getPageSize',
      'getPageTotal',
      'getIsDetail',
      'getSearchCondition',
    ]);
    const { SET_IS_DETAIL, SET_SEARCH_CONDITION } = useMutations('order', ['SET_IS_DETAIL', 'SET_SEARCH_CONDITION']);
    const { doSearchOrderList, doGetOrderRecord } = useActions('order', ['doSearchOrderList', 'doGetOrderRecord']);
    //初始化
    const listTable = ref(null);
    const currentPage = ref(1);
    const query = { page: route.query.page && getSearchCondition.value ? route.query.page : 1 };
    const searchDataInit = {
      date: [getFormatDate(), getFormatDate()],
      shop_id: '',
      source_platform: [],
      take_away_method: [],
      status: [],
      invoice_no: '',
      serial_number: '',
      invoice_status: '',
    };
    router.push({ query, replace: true }).then(async () => {
      currentPage.value = Number(route.query.page);
      if (route.path === '/order/list' && !getIsDetail.value) {
        SET_SEARCH_CONDITION(searchDataInit);
        await doSearchOrderList({
          search: searchDataInit,
          start: (currentPage.value - 1) * getPageSize.value,
          type: 'search',
        });
      } else {
        SET_IS_DETAIL(false);
      }
    });
    //點擊分頁
    const handleCurrentChange = () => {
      const query = { page: currentPage.value };
      router.push({ query });
    };
    watch(
      () => route.query.page,
      async () => {
        if (route.query.page && getSearchCondition.value) {
          currentPage.value = Number(route.query.page);
          await doSearchOrderList({
            search: getSearchCondition.value,
            start: (currentPage.value - 1) * getPageSize.value,
            type: 'page',
          });
          listTable.value.scrollBarRef.scrollTo(0, 0);
        }
      }
    );

    //點擊詳細
    const doGetOrderDetail = useActions('order', ['doGetOrderDetail']);
    const handleClickDetail = async (index, item) => {
      await doGetOrderDetail(item);
      router.push(`/order/list/detail?order_id=${item.order_id}`);
    };

    // 下載csv
    const downloadList = async () => {
      await doGetOrderRecord(getSearchCondition.value).then(function (csvContent) {
        downloadCSV(csvContent, '_訂單報表');
      });
    };

    return {
      formatDateTime,
      mapInvoiceStatus,
      mapOrderStatus,
      getConsumer,
      getOrderList,
      listTable,
      currentPage,
      getPageSize,
      getPageTotal,
      handleCurrentChange,
      handleClickDetail,
      downloadList,
    };
  },
};
</script>
<style lang="scss" scoped>
$padding-unit: 10px;
$row-height: 55px;
$pagination-height: 50px;
.order-list-wrap {
  width: 100%;
  height: 100%;
  padding: $padding-unit $padding-unit $pagination-height !important;
  position: relative;
  overflow: scroll;
  @include white-bg;
}
.button-wrap {
  position: absolute;
  top: calc($padding-unit + $row-height / 2);
  right: 22px;
  z-index: 10;
  transform: translateY(-50%);
  width: 110px;
}
.pagination {
  position: absolute;
  left: 50%;
  bottom: calc($pagination-height / 2);
  transform: translate(-50%, 50%);
}
</style>
